exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-conversion-optimisation-js": () => import("./../../../src/pages/conversion-optimisation.js" /* webpackChunkName: "component---src-pages-conversion-optimisation-js" */),
  "component---src-pages-expert-review-js": () => import("./../../../src/pages/expert-review.js" /* webpackChunkName: "component---src-pages-expert-review-js" */),
  "component---src-pages-human-machine-interface-js": () => import("./../../../src/pages/human-machine-interface.js" /* webpackChunkName: "component---src-pages-human-machine-interface-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-remote-ux-research-js": () => import("./../../../src/pages/remote-ux-research.js" /* webpackChunkName: "component---src-pages-remote-ux-research-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-usability-testing-js": () => import("./../../../src/pages/usability-testing.js" /* webpackChunkName: "component---src-pages-usability-testing-js" */),
  "component---src-pages-user-testing-js": () => import("./../../../src/pages/user-testing.js" /* webpackChunkName: "component---src-pages-user-testing-js" */),
  "component---src-pages-ux-design-js": () => import("./../../../src/pages/ux-design.js" /* webpackChunkName: "component---src-pages-ux-design-js" */),
  "component---src-pages-ux-lab-js": () => import("./../../../src/pages/ux-lab.js" /* webpackChunkName: "component---src-pages-ux-lab-js" */),
  "component---src-pages-ux-research-js": () => import("./../../../src/pages/ux-research.js" /* webpackChunkName: "component---src-pages-ux-research-js" */),
  "component---src-pages-ux-services-js": () => import("./../../../src/pages/ux-services.js" /* webpackChunkName: "component---src-pages-ux-services-js" */),
  "component---src-pages-uxlinks-js": () => import("./../../../src/pages/uxlinks.js" /* webpackChunkName: "component---src-pages-uxlinks-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-page-template-js": () => import("./../../../src/templates/TagPageTemplate.js" /* webpackChunkName: "component---src-templates-tag-page-template-js" */)
}

